.circularImage {
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin: 2%;
    width: 100%;
    max-width: 220px;
}

.circularImageIcon {
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
    margin: 2%;
    width: 100%;
    max-width: 50px;
}

.basicPaper {
    padding: 2%;
    border-radius: 10px !important;
}

.innerPaper {
    padding: 2%;
    margin: 2% .5% .5% .5%;
    border-radius: 10px !important;
    display: flex;
}

.innerPaperTag {
    padding: 2%;
    margin: 1% .5% .5% .5%;
    border-radius: 10px !important;
}

.timelineItemWrapperLeft {
    display: flex;
    justify-content: flex-end;
}

.timelinePaperRight {
    @media (max-width: 600px) {
        width: 100%;
        padding: 5%;
    }

    @media (min-width: 601px) and (max-width: 1535px) {
        padding: 3%;
    }

    @media (min-width: 601px) {
        width: 70%;
    }

    padding: 2% 2% 2% 0%;
    border-radius: 10px !important;
}

.timelinePaperLeft {
    @media (min-width: 601px) and (max-width: 1535px) {
        padding: 3%;
    }

    @media (min-width: 601px) {
        width: 70%;
    }

    padding: 2% 0 2% 2%;
    border-radius: 10px !important;
}

.timelineIconLeft {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
        justify-content: flex-start;
    }

    @media (min-width: 601px) and (max-width: 1535px) {
        justify-content: flex-end;
    }

    @media (min-width: 1536px) {
        justify-content: center;
    }
}

.timelineIconRight {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: 1536px) {
        justify-content: center;
    }
}

.timelineBodyText {
    padding-top: '2%' !important;
    margin-bottom: 0 !important;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(15%);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform .8s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}